var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.reward.Id ? 'Cập nhật Giải thưởng' : 'Thêm Giải thưởng',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",staticStyle:{"max-height":"calc(100vh - 11.6rem)"}},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Chương trình")]),_c('v-select',{class:{
                'is-invalid border-danger rounded': !!_vm.inValidObject[
                  'reward.ProgramId'
                ],
              },attrs:{"options":_vm.programs,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chọn chương trình","disabled":!!_vm.reward.Id},model:{value:(_vm.reward.ProgramId),callback:function ($$v) {_vm.$set(_vm.reward, "ProgramId", $$v)},expression:"reward.ProgramId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["reward.ProgramId"])+" ")])],1)]),_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Tên Giải thưởng*","placeholder":"Tên Giải thưởng","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['reward.Name'],
            },"invalid-feedback":_vm.inValidObject['reward.Name']},model:{value:(_vm.reward.Name),callback:function ($$v) {_vm.$set(_vm.reward, "Name", $$v)},expression:"reward.Name"}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"type":"number","label":"Xếp hạng*","placeholder":"Xếp hạng","min":"1","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['reward.RewardLevel'],
            },"invalid-feedback":_vm.inValidObject['reward.RewardLevel']},model:{value:(_vm.reward.RewardLevel),callback:function ($$v) {_vm.$set(_vm.reward, "RewardLevel", $$v)},expression:"reward.RewardLevel"}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"type":"number","label":"Số lượng quà","placeholder":"Số lượng quà","min":"0","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['reward.Qty'],
            },"invalid-feedback":_vm.inValidObject['reward.Qty']},model:{value:(_vm.reward.Qty),callback:function ($$v) {_vm.$set(_vm.reward, "Qty", $$v)},expression:"reward.Qty"}})],1),_c('CCol',{attrs:{"md":"4"}},[_c('CInput',{attrs:{"type":"number","label":"Quà đã trúng","placeholder":"Quà đã trúng","value":_vm.$func.addCommas(_vm.reward.QtyUsed),"disabled":""}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CTextarea',{attrs:{"label":"Mô tả","placeholder":"Mô tả Giải thưởng","add-input-classes":{
              'is-invalid': !!_vm.inValidObject['reward.Description'],
            },"invalid-feedback":_vm.inValidObject['reward.Description']},model:{value:(_vm.reward.Description),callback:function ($$v) {_vm.$set(_vm.reward, "Description", $$v)},expression:"reward.Description"}})],1),_c('CCol',{attrs:{"md":"12"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Chọn Trạng thái","value":_vm.reward.Status,"options":Object.keys(_vm.$const.STATUS_TEXT).map(function (_) {
                return {
                  value: _,
                  label: _vm.$const.STATUS_TEXT[_],
                };
              }),"add-input-classes":{
              'is-invalid': !!_vm.inValidObject['reward.Status'],
            },"invalid-feedback":_vm.inValidObject['reward.Status']},on:{"update:value":function($event){return _vm.$set(_vm.reward, "Status", $event)}}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.saveReward}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Lưu thông tin ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }