var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('span',{staticClass:"font-weight-bold"},[_c('CIcon',{attrs:{"name":"cilGift"}}),_vm._v(" DS Giải thưởng ")],1),_c('CButton',{staticClass:"float-right",attrs:{"color":"success","size":"sm"},on:{"click":function($event){$event.preventDefault();return (function (e) {
            _vm.editing = true;
            _vm.rewardId = 0;
          })($event)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Giải thưởng ")],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"form-row"},[_c('label',{staticClass:"col-form-label col-4 text-right"},[_vm._v("Chương trình")]),_c('v-select',{staticClass:"col-8",attrs:{"options":_vm.programs,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chương trình"},model:{value:(_vm.programId),callback:function ($$v) {_vm.programId=$$v},expression:"programId"}})],1)])]),_c('CCol',{attrs:{"md":"3"}},[_c('CSelect',{attrs:{"label":"Trạng thái","placeholder":"Trạng thái","value":_vm.rewardStatus,"options":[
              { value: null, label: 'Tất cả' } ].concat( Object.keys(_vm.statusList).map(function (_) {
                return {
                  value: _,
                  label: _vm.statusList[_],
                };
              }) ),"horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"update:value":function($event){_vm.rewardStatus=$event}}})],1),_c('CCol',{attrs:{"md":"3"}},[_c('CInput',{attrs:{"label":"Tên GT","placeholder":"Tên Giải thưởng","horizontal":{
              label: 'col-4 text-right',
              input: 'col-8',
            }},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doFilter($event)}},model:{value:(_vm.rewardName),callback:function ($$v) {_vm.rewardName=$$v},expression:"rewardName"}})],1),_c('CCol',{staticClass:"text-center",attrs:{"md":"3"}},[_c('CButton',{staticClass:"mr-2 mb-3",attrs:{"color":"primary"},on:{"click":_vm.doFilter}},[_c('CIcon',{attrs:{"name":"cil-search","custom-classes":"c-icon m-0"}}),_vm._v(" Tìm kiếm ")],1),_c('CButton',{staticClass:"mb-3",attrs:{"color":"secondary"},on:{"click":_vm.clear}},[_c('CIcon',{attrs:{"name":"cil-x-circle","custom-classes":"c-icon m-0"}}),_vm._v(" Đặt lại ")],1)],1)],1),_c('odata-table',{ref:"lstReward",attrs:{"url":"odata/Reward","filter":_vm.filter,"select":"Id,Name,RewardLevel,Qty,QtyUsed,Description,Status,CreatedAt","expand":"Program($select=Name)","sortBy":"RewardLevel asc","pageSize":_vm.pageSize,"colSetting":{
          Action: {
            display: '#',
            sortable: false,
            style: 'min-width: 50px;',
          },
          ProgramName: {
            field: 'Program/Name',
            display: 'Chương trình',
            sortable: true,
            style: 'min-width: 120px',
          },
          Name: {
            field: 'Name',
            display: 'Tên Giải thưởng',
            sortable: true,
            style: 'min-width: 150px',
          },
          RewardLevel: {
            field: 'RewardLevel',
            display: 'Xếp hạng',
            sortable: true,
            style: 'min-width: 50px',
          },
          Qty: {
            field: 'Qty',
            display: 'SL',
            sortable: true,
            style: 'min-width: 50px',
          },
          QtyUsed: {
            field: 'QtyUsed',
            display: 'SL trúng',
            sortable: true,
            style: 'min-width: 70px',
          },
          Description: {
            field: 'Description',
            display: 'Mô tả',
            sortable: true,
            style: 'min-width: 150px',
          },
          Status: {
            field: 'Status',
            display: 'Trạng thái',
            sortable: true,
            style: 'min-width: 100px',
          },
          CreatedAt: {
            field: 'CreatedAt',
            display: 'Ngày tạo',
            sortable: true,
            style: 'min-width: 120px',
          },
        }},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var rows = ref.rows;
return _vm._l((rows),function(row,i){return _c('tr',{key:("tr-" + i)},[_c('td',[_vm._v(_vm._s(i + 1 + (_vm.$refs.lstReward.page - 1) * _vm.pageSize)+".")]),_c('td',[_vm._v(" "+_vm._s(row.Program.Name)+" ")]),_c('td',[_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:","title":("Cập nhật: " + (row.Name))},on:{"click":function (e) {
                    _vm.rewardId = row.Id;
                    _vm.editing = true;
                  }}},[_c('CIcon',{attrs:{"name":"cil-pencil"}}),_vm._v(" "+_vm._s(row.Name)+" ")],1)]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(row.RewardLevel))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$func.addCommas(row.Qty)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$func.addCommas(row.QtyUsed)))]),_c('td',[_vm._v(" "+_vm._s(row.Description)+" ")]),_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(row.Status)}},[_vm._v(" "+_vm._s(_vm.$const.STATUS_TEXT[row.Status])+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$moment .utc(row.CreatedAt) .utcOffset("-08:00") .format("DD/MM/YY HH:mm"))+" ")])])})}}])})],1)],1),_c('detail',{attrs:{"editing":_vm.editing,"rewardId":_vm.rewardId,"programs":_vm.programs},on:{"update:editing":function($event){_vm.editing=$event},"update:rewardId":function($event){_vm.rewardId=$event},"update:reward-id":function($event){_vm.rewardId=$event},"reload":function($event){return _vm.$refs.lstReward.loadData()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }