<template>
  <CModal
    :title="reward.Id ? 'Cập nhật Giải thưởng' : 'Thêm Giải thưởng'"
    :show.sync="detailModal"
    :closeOnBackdrop="false"
    centered
  >
    <template v-slot:body-wrapper>
      <div
        class="modal-body overflow-auto"
        style="max-height: calc(100vh - 11.6rem);"
      >
        <CRow>
          <CCol md="12">
            <div class="form-group">
              <label>Chương trình</label>
              <v-select
                v-model="reward.ProgramId"
                :options="programs"
                :reduce="(p) => p.Id"
                label="Name"
                placeholder="Chọn chương trình"
                :class="{
                  'is-invalid border-danger rounded': !!inValidObject[
                    'reward.ProgramId'
                  ],
                }"
                :disabled="!!reward.Id"
              />
              <div class="invalid-feedback">
                {{ inValidObject["reward.ProgramId"] }}
              </div>
            </div>
          </CCol>

          <CCol md="12">
            <CInput
              label="Tên Giải thưởng*"
              placeholder="Tên Giải thưởng"
              v-model="reward.Name"
              :add-input-classes="{
                'is-invalid': !!inValidObject['reward.Name'],
              }"
              :invalid-feedback="inValidObject['reward.Name']"
            />
          </CCol>

          <CCol md="4">
            <CInput
              type="number"
              label="Xếp hạng*"
              placeholder="Xếp hạng"
              v-model="reward.RewardLevel"
              min="1"
              :add-input-classes="{
                'is-invalid': !!inValidObject['reward.RewardLevel'],
              }"
              :invalid-feedback="inValidObject['reward.RewardLevel']"
            />
          </CCol>

          <CCol md="4">
            <CInput
              type="number"
              label="Số lượng quà"
              placeholder="Số lượng quà"
              v-model="reward.Qty"
              min="0"
              :add-input-classes="{
                'is-invalid': !!inValidObject['reward.Qty'],
              }"
              :invalid-feedback="inValidObject['reward.Qty']"
            />
          </CCol>

          <CCol md="4">
            <CInput
              type="number"
              label="Quà đã trúng"
              placeholder="Quà đã trúng"
              :value="$func.addCommas(reward.QtyUsed)"
              disabled
            />
          </CCol>

          <CCol md="12">
            <CTextarea
              label="Mô tả"
              placeholder="Mô tả Giải thưởng"
              v-model="reward.Description"
              :add-input-classes="{
                'is-invalid': !!inValidObject['reward.Description'],
              }"
              :invalid-feedback="inValidObject['reward.Description']"
            />
          </CCol>

          <CCol md="12">
            <CSelect
              label="Trạng thái"
              placeholder="Chọn Trạng thái"
              :value.sync="reward.Status"
              :options="
                Object.keys($const.STATUS_TEXT).map((_) => {
                  return {
                    value: _,
                    label: $const.STATUS_TEXT[_],
                  };
                })
              "
              :add-input-classes="{
                'is-invalid': !!inValidObject['reward.Status'],
              }"
              :invalid-feedback="inValidObject['reward.Status']"
            />
          </CCol>
        </CRow>
      </div>
    </template>
    <template v-slot:footer>
      <CButton class="btn btn-primary" @click="saveReward" :disabled="saving">
        <CIcon name="cil-save" />
        Lưu thông tin
      </CButton>
      <div class="d-inline-block font-italic" v-if="saving">
        <CSpinner color="info" size="sm" class="ml-2" />
        Đang xử lý...
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["editing", "rewardId", "programs"],
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      reward: this.newReward(),
      saving: false,
    };
  },
  watch: {
    async rewardId(val) {
      await this.loadRewardInfo(val);
    },
    editing(val) {
      this.detailModal = val;
      if (!val) {
        this.$emit("update:rewardId", null);
      }
    },
    detailModal(val) {
      this.$emit("update:editing", val);
    },
  },

  methods: {
    newReward() {
      return {
        Id: 0,
        ProgramId: null,
        Name: null,
        RewardLevel: null,
        Qty: null,
        QtyUsed: 0,
        Description: null,
        Status: this.$const.STATUS.Active.toString(),
      };
    },

    async loadRewardInfo(rewardId) {
      try {
        this.inValidObject = {};
        if (rewardId) {
          let filters = [];
          rewardId && filters.push(`Id eq ${rewardId}`);
          let resp = await this.$http.get(`odata/Reward`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $select:
                "Id,ProgramId,Name,RewardLevel,Qty,QtyUsed,Description,Status",
            },
          });
          if (resp && resp.status == 200) {
            let _ = resp.data.value[0];
            this.reward = {
              Id: _.Id,
              ProgramId: _.ProgramId,
              Name: _.Name,
              RewardLevel: _.RewardLevel,
              Qty: _.Qty,
              QtyUsed: _.QtyUsed,
              Description: _.Description,
              Status: _.Status.toString(),
            };
          }
        } else {
          this.reward = this.newReward();
        }
      } catch (error) {
        alert(error);
      }
    },

    async saveReward() {
      let reward = this.reward;
      this.inValidObject = this.checkValid(reward);
      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }

      this.saving = true;
      try {
        let rewardData = {
          Name: reward.Name,
          RewardLevel: reward.RewardLevel,
          Qty: reward.Qty ? parseInt(reward.Qty) : null,
          Description: reward.Description,
          Status: parseInt(reward.Status),
        };
        // Customer
        if (!reward.Id) {
          // post
          rewardData.QtyUsed = 0;
          rewardData.ProgramId = reward.ProgramId;

          let resp = await this.$http.post(`odata/Reward`, rewardData);
          if (resp && resp.status == 201) {
            this.detailModal = false;
            this.$emit("reload");
          }
        } else {
          // patch
          let resp = await this.$http.patch(
            `odata/Reward/${reward.Id}`,
            rewardData
          );
          if (resp && resp.status == 204) {
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(reward) {
      let inValidObject = {};

      if (!reward.ProgramId)
        inValidObject["reward.ProgramId"] = "Vui lòng chọn Chương trình!";
      if (!reward.Name)
        inValidObject["reward.Name"] = "Vui lòng nhập Tên giải thưởng!";
      if (!reward.RewardLevel || parseInt(reward.RewardLevel) < 1)
        inValidObject["reward.RewardLevel"] = "Vui lòng nhập đúng Thứ hạng!";
      if (!reward.Qty || parseInt(reward.Qty) <= 0)
        inValidObject["reward.Qty"] = "Số lượng quà không hợp lệ!";
      if (!reward.Status)
        inValidObject["reward.Status"] = "Vui lòng chọn Trạng Thái!";

      return inValidObject;
    },
  },
};
</script>
